import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import LoadMtaQuoteWrapper from 'components/ApiRequestWrapper/LoadMtaQuoteWrapper';
import LoadPolicyWrapper from 'components/ApiRequestWrapper/LoadPolicyWrapper';
import Layout from 'components/Layout';
import MtaChangeCarPage from 'components/MtaChangeCar/MtaChangeCarPage';
import MtaStartDatePage from 'components/MtaStartDate/MtaStartDatePage';
import useMtaStartDatePage from 'components/MtaStartDate/useMtaStartDatePage';
import { trackCheckoutEvent } from 'helpers/ecommerceTracking';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { useUser } from 'state/user/state';
import { CsMeta } from 'types/contentStack';

type ChangeCarProps = {
  data: {
    csMtaChangeCar: {
      meta: CsMeta;
    };
  };
  location: Location & {
    state?: { clearManualLookup: boolean | null };
  };
};

export const query = graphql`
  query {
    csMtaChangeCar {
      meta {
        meta_title
      }
    }
  }
`;

const ChangeCar: React.FC<ChangeCarProps & PolicyNumberProps> = ({
  data: {
    csMtaChangeCar: { meta },
  },
  policyNumber,
  location,
}) => {
  const { isLoading, loadingMessage, requestHandler } = useApiRequestHandler();
  const {
    startDateFormProps,
    hasExistingMtaRequest,
    showStartDatePage,
    toggleStartDatePage,
    moveNextOrGenerateMta,
  } = useMtaStartDatePage(policyNumber, 'Change car', requestHandler);

  useEffect(() => trackCheckoutEvent(1, null, 'MTA'), []);

  const [user] = useUser();
  usePageTracking(meta.meta_title, !!user?.isLoggedIn);

  return (
    <Layout meta={meta} pageType="mtaAndRenewal">
      <LoadPolicyWrapper policyNumber={policyNumber}>
        <LoadMtaQuoteWrapper policyNumber={policyNumber}>
          {isLoading && <LoadingOverlay loadingMessage={loadingMessage || ''} />}
          {!showStartDatePage ? (
            <MtaChangeCarPage
              hasExistingMtaRequest={hasExistingMtaRequest}
              moveNext={moveNextOrGenerateMta}
              policyNumber={policyNumber}
              clearManualLookup={location.state?.clearManualLookup === true}
            />
          ) : (
            <MtaStartDatePage
              formState={startDateFormProps.formState}
              updateFormState={startDateFormProps.updateFormState}
              moveNext={moveNextOrGenerateMta}
              moveBack={toggleStartDatePage}
            />
          )}
        </LoadMtaQuoteWrapper>
      </LoadPolicyWrapper>
    </Layout>
  );
};

export default withPolicyNumber(ChangeCar);
