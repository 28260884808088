import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import mapVehicleDetails from 'api/quote/quoteToFormMappings/mapVehicleDetails';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import AboutYourCarForm from 'forms/AboutYourCarForm';
import { useChangeCarDetails } from 'state/formData/mta/changeCar';
import { useMtaQuote } from 'state/mta/mtaQuote';
import { usePolicyData } from 'state/policy/policy';
import { CsHero } from 'types/contentStack';

type MtaChangeCarPageContent = {
  csMtaChangeCar: {
    hero: CsHero;
    next_button_text: string;
  };
  csMtaStartDate: {
    next_button_text: string;
  };
};

export const query = graphql`
  query {
    csMtaChangeCar {
      hero {
        heading
        subheading
      }
      next_button_text
    }
    csMtaStartDate {
      next_button_text
    }
  }
`;

type MtaChangeCarPageProps = {
  hasExistingMtaRequest: boolean;
  moveNext: () => void;
  policyNumber: string;
  clearManualLookup: boolean;
};

const MtaChangeCarPage: React.FC<MtaChangeCarPageProps> = ({
  hasExistingMtaRequest,
  moveNext,
  policyNumber,
  clearManualLookup,
}) => {
  const {
    csMtaChangeCar: {
      hero: { heading, subheading },
      next_button_text,
    },
    csMtaStartDate: { next_button_text: startDateNextButtonText },
  } = useStaticQuery<MtaChangeCarPageContent>(query);

  const mtaQuote = useMtaQuote();
  const [changeCarDetails, updateChangeCarDetails] = useChangeCarDetails();
  const policy = usePolicyData();

  useEffect(() => {
    if (mtaQuote?.policyNumber === policyNumber && mtaQuote?.mtaType.isChangeOfVehicle) {
      const updatedChangeCarDetails = mapVehicleDetails(mtaQuote);
      updateChangeCarDetails({
        ...updatedChangeCarDetails,
        isManualSearch: clearManualLookup
          ? false
          : updatedChangeCarDetails.isManualSearch,
      });
    }
    if (policy?.migratedFields) {
      updateChangeCarDetails({
        migratedFields: {
          ...policy.migratedFields.vehicleDetails,
          annualBusinessMileageIsMigrated:
            policy.migratedFields.policyHolder?.annualBusinessMileageIsMigrated,
        },
      });
    }
  }, [mtaQuote, policyNumber, updateChangeCarDetails, clearManualLookup, policy]);

  return (
    <>
      <HeroPlain heading={heading} subhead={subheading || undefined} />
      <AboutYourCarForm
        moveNextText={hasExistingMtaRequest ? startDateNextButtonText : next_button_text}
        moveNext={moveNext}
        formState={changeCarDetails}
        updateFormState={updateChangeCarDetails}
      />
    </>
  );
};

export default MtaChangeCarPage;
